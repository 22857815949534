import { useState, useEffect, useContext, Fragment } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import { Dialog, Menu, Transition } from '@headlessui/react';
import { Outlet, NavLink, useNavigate, useLocation } from "react-router-dom";
import { GlobalContext } from "../provider/globalProvider.js";

import {
    Bars3Icon,
    BellIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
    ChatBubbleLeftEllipsisIcon
  } from '@heroicons/react/24/outline'
  import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'

function classNames(...classes) {
return classes.filter(Boolean).join(' ')
}

export default function GuestDashboardTemplate() {
    const { isAuthenticated, user } = useAuth0();
    const infoToPass = useContext(GlobalContext);
    const navigate = useNavigate();
    const location = useLocation();
    
    const [sidebarNavigation, setSidebarNavigation] = useState("");
    const [dropdownNavigation, setDropdownNavigation] = useState("");
    const [template, setTemplate] = useState("");
    const [pathName, setPathName] = useState("");
    const [sidebarOpen, setSidebarOpen] = useState(false);
    const [userData, setUserData] = useState("");

    const navigation = [
      { name: "Guest Dashboard", href: "/guestDashboard", icon: HomeIcon, current: false },
      { name: "Saved", href: "/guestDashboard/saved", icon: FolderIcon, current: false },
      { name: "Messenger", href: "/guestDashboard/messenger", icon: ChatBubbleLeftEllipsisIcon, current: false },
      { name: "Profile", href: "/guestDashboard/profile", icon: UsersIcon, current: false },
    ];

    const userNavigation = [
      { name: "Home", href: "/listingAll" },
      { name: "Manager Dashboard", href: "/managerDashboard" },
      { name: "Sign out", href: process.env.REACT_APP_LOGOUT_URL },
    ];

    const selectedPageButtonHandler = (array, route) => {
      return array.map((navObj) => {
        if (navObj.href === route) {
          navObj.current = true;
        };
        return navObj;
      })
    }

    useEffect(() => {
      let route = location.pathname;

      let updatedNav = selectedPageButtonHandler(navigation, route);
      setSidebarNavigation(updatedNav);
      setDropdownNavigation(userNavigation);
    },[])

    useEffect(() => {
      if (pathName) {
        let updatedNav = selectedPageButtonHandler(navigation, pathName);
        setSidebarNavigation(updatedNav);
      }
    },[pathName])

    useEffect(() => {
      renderSideBarWithHeader();
    },[sidebarNavigation, dropdownNavigation, sidebarOpen])

    useEffect(() => {
        if (isAuthenticated) {
          setUserData(user);
        }
    }, [isAuthenticated, user]);

    const handleClick = (name) => {
        navigate('/listingAll');
    }

    const renderSideBarWithHeader = () => {
      if (userData && sidebarNavigation && dropdownNavigation) {
        setTemplate(
          <>
            <div>
              <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog
                  as="div"
                  className="relative z-50 lg:hidden"
                  onClose={setSidebarOpen}
                >
                  <Transition.Child
                    as={Fragment}
                    enter="transition-opacity ease-linear duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="transition-opacity ease-linear duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="fixed inset-0 bg-gray-900/80" />
                  </Transition.Child>

                  <div className="fixed inset-0 flex">
                    <Transition.Child
                      as={Fragment}
                      enter="transition ease-in-out duration-300 transform"
                      enterFrom="-translate-x-full"
                      enterTo="translate-x-0"
                      leave="transition ease-in-out duration-300 transform"
                      leaveFrom="translate-x-0"
                      leaveTo="-translate-x-full"
                    >
                      <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                        <Transition.Child
                          as={Fragment}
                          enter="ease-in-out duration-300"
                          enterFrom="opacity-0"
                          enterTo="opacity-100"
                          leave="ease-in-out duration-300"
                          leaveFrom="opacity-100"
                          leaveTo="opacity-0"
                        >
                          <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                            <button
                              type="button"
                              className="-m-2.5 p-2.5"
                              onClick={() => setSidebarOpen(false)}
                            >
                              <span className="sr-only">Close sidebar</span>
                              <XMarkIcon
                                className="h-6 w-6 text-white"
                                aria-hidden="true"
                              />
                            </button>
                          </div>
                        </Transition.Child>
                        <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-white px-6 pb-4">
                          <div className="flex h-16 shrink-0 items-center">
                            <h1
                              className="text-2xl font-sans text-slate-900 cursor-pointer font-bold"
                              onClick={handleClick}
                            >
                              powderful.xyz
                            </h1>
                          </div>
                          <nav className="flex flex-1 flex-col">
                            <ul className="flex flex-1 flex-col gap-y-7">
                              <li>
                                <ul className="-mx-2 space-y-1">
                                  {sidebarNavigation.map((item) => (
                                    <li key={item.name}>
                                      <NavLink
                                        to={item.href}
                                        onClick={() => {
                                          setSidebarOpen(false);
                                          setPathName(item.href);
                                        }}
                                        className={classNames(
                                          item.current
                                            ? "bg-gray-50 text-indigo-600"
                                            : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                          "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                        )}
                                      >
                                        <item.icon
                                          className={classNames(
                                            item.current
                                              ? "text-indigo-600"
                                              : "text-gray-400 group-hover:text-indigo-600",
                                            "h-6 w-6 shrink-0"
                                          )}
                                          aria-hidden="true"
                                        />
                                        {item.name}
                                      </NavLink>
                                    </li>
                                  ))}
                                </ul>
                              </li>
                            </ul>
                          </nav>
                        </div>
                      </Dialog.Panel>
                    </Transition.Child>
                  </div>
                </Dialog>
              </Transition.Root>

              {/* Static sidebar for desktop */}
              <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                <div className="flex grow flex-col gap-y-5 overflow-y-auto border-r border-gray-200 bg-white px-6 pb-4">
                  <div className="flex h-16 shrink-0 items-center">
                    <h1
                      className="text-2xl font-sans text-slate-900 cursor-pointer font-bold"
                      onClick={handleClick}
                    >
                      powderful.xyz
                    </h1>
                  </div>
                  <nav className="flex flex-1 flex-col">
                    <ul className="flex flex-1 flex-col gap-y-7">
                      <li>
                        <ul className="-mx-2 space-y-1">
                          {sidebarNavigation.map((item) => (
                            <li key={item.name}>
                              <NavLink
                                to={item.href}
                                onClick={() => setPathName(item.href)}
                                className={classNames(
                                  item.current
                                    ? "bg-gray-50 text-indigo-600"
                                    : "text-gray-700 hover:text-indigo-600 hover:bg-gray-50",
                                  "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                )}
                              >
                                <item.icon
                                  className={classNames(
                                    item.current
                                      ? "text-indigo-600"
                                      : "text-gray-400 group-hover:text-indigo-600",
                                    "h-6 w-6 shrink-0"
                                  )}
                                  aria-hidden="true"
                                />
                                {item.name}
                              </NavLink>
                            </li>
                          ))}
                        </ul>
                      </li>
                    </ul>
                  </nav>
                </div>
              </div>

              <div className="lg:pl-72">
                <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                  <button
                    type="button"
                    className="-m-2.5 p-2.5 text-gray-700 lg:hidden"
                    onClick={() => setSidebarOpen(true)}
                  >
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                  </button>

                  {/* Separator */}
                  <div
                    className="h-6 w-px bg-gray-200 lg:hidden"
                    aria-hidden="true"
                  />

                  <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                    <form
                      className="relative flex flex-1"
                      action="#"
                      method="GET"
                    >
                      <label htmlFor="search-field" className="sr-only">
                        Search
                      </label>
                      <MagnifyingGlassIcon
                        className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400"
                        aria-hidden="true"
                      />
                      <input
                        id="search-field"
                        className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm"
                        placeholder="Search..."
                        type="search"
                        name="search"
                      />
                    </form>
                    <div className="flex items-center gap-x-4 lg:gap-x-6">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500"
                      >
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                      </button>

                      {/* Separator */}
                      <div
                        className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-200"
                        aria-hidden="true"
                      />

                      {/* Profile dropdown */}
                      <Menu as="div" className="relative">
                        <Menu.Button className="-m-1.5 flex items-center p-1.5">
                          <span className="sr-only">Open user menu</span>
                          <img
                            className="h-8 w-8 rounded-full bg-gray-50"
                            src={userData.picture}
                            alt="img"
                          />
                          <span className="hidden lg:flex lg:items-center">
                            <span
                              className="ml-4 text-sm font-semibold leading-6 text-gray-900"
                              aria-hidden="true"
                            >
                              {userData.nickname}
                            </span>
                            <ChevronDownIcon
                              className="ml-2 h-5 w-5 text-gray-400"
                              aria-hidden="true"
                            />
                          </span>
                        </Menu.Button>
                        <Transition
                          as={Fragment}
                          enter="transition ease-out duration-100"
                          enterFrom="transform opacity-0 scale-95"
                          enterTo="transform opacity-100 scale-100"
                          leave="transition ease-in duration-75"
                          leaveFrom="transform opacity-100 scale-100"
                          leaveTo="transform opacity-0 scale-95"
                        >
                          <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                            {dropdownNavigation.map((item) => (
                              <Menu.Item key={item.name}>
                                {({ active }) => (
                                  <NavLink
                                    to={item.href}
                                    className={classNames(
                                      active ? "bg-gray-50" : "",
                                      "block px-3 py-1 text-sm leading-6 text-gray-900"
                                    )}
                                  >
                                    {item.name}
                                  </NavLink>
                                )}
                              </Menu.Item>
                            ))}
                          </Menu.Items>
                        </Transition>
                      </Menu>
                    </div>
                  </div>
                </div>
                <main className="py-10">
                  <div className="px-4 sm:px-6 lg:px-8">
                    <Outlet />
                  </div>
                </main>
              </div>
            </div>
          </>
        );
      }
    };

    return (
      <>
      {template}
      </>
    )
};